<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="订单创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Tabs v-model="tabActiveValue">
      <TabPane label="邀请列表" name="inviteTab">
        <Table stripe :data="inviteList" :columns="inviteTableColumns" :loading="tableLoading" ref="table" />
        <Pager :total="inviteListCount" :current.sync="invitePage" />
      </TabPane>
      <TabPane label="积分列表" name="creditTab">
        <Table stripe :data="creditList" :columns="creditTableColumns" :loading="tableLoading" ref="table" />
        <Pager :total="creditListCount" :current.sync="creditPage" />
      </TabPane>
    </Tabs>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryUserInvite, queryUserCredit } from "@/api"
import "@/components/tableUtil"

export default {
  name: "UserInviteAndCreditPage",
  components: { ContentWrapper, Pager },
  props: {
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      topLinks: [{ title: "用户邀请和积分列表", link: this.$route.path }],
      tabActiveValue: "inviteTab",
      inviteList: [],
      inviteListCount: 0,
      creditList: [],
      creditListCount: 0,
      tableLoading: false,
      createdAtRange: ["", ""],
      invitePage: 1,
      creditPage: 1,
      inviteTableColumns: [
        {
          title: "邀请者",
          key: "inviter",
          render(h, p) {
            return h("div", p.row.inviter.nickname)
          },
        },
        {
          title: "受邀者",
          key: "invitee",
          render(h, p) {
            return h("div", p.row.invitee.nickname)
          },
        },
        {
          title: "受邀者设备号",
          key: "device",
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
        },
      ],
      creditTableColumns: [
        {
          title: "积分",
          key: "deltaValue",
            render(h, p) {
                return h("div", p.row.deltaValue>0?"+"+p.row.deltaValue:"-"+p.row.deltaValue)
            },
        },
        {
          title: "来源",
          key: "creditSource",
        },
        {
          title: "创建时间",
          key: "createAt"
        },
      ],
    }
  },
  computed: {},
  watch: {
    async userId() {
      this.tabActiveValue = "inviteTab"
      await this.inviteReload()
      await this.creditReload()
    },
    async invitePage() {
      await this.inviteReload()
    },
    async creditPage() {
      await this.creditReload()
    },
    async createdAtRange() {
      if (this.tabActiveValue === "inviteTab") {
        await this.inviteReload()
      } else {
        await this.creditReload()
      }
    },
  },
  async mounted() {
    await this.inviteReload()
    await this.creditReload()
  },
  methods: {
    async inviteReload() {
      try {
        if (this.userId) {
          this.tableLoading = true
          let startTime = null,
            endTime = null
          if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
            startTime = this.createdAtRange[0].getTime()
            endTime = this.createdAtRange[1].getTime()
          }
          const { items, count } = await queryUserInvite(this.userId, this.invitePage, startTime, endTime)
          this.inviteList = items
          this.inviteListCount = count
          this.tableLoading = false
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async creditReload() {
      try {
        if (this.userId) {
          this.tableLoading = true
          let startTime = null,
            endTime = null
          if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
            startTime = this.createdAtRange[0].getTime()
            endTime = this.createdAtRange[1].getTime()
          }
          const { items, count } = await queryUserCredit(this.userId, this.creditPage, startTime, endTime)
          this.creditList = items
          this.creditListCount = count
          this.tableLoading = false
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    // exportData() {
    //   const q = this.queryForm
    //   let startTime = ""
    //   let endTime = ""
    //   if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
    //     startTime = this.createdAtRange[0].getTime()
    //     endTime = this.createdAtRange[1].getTime()
    //   }
    //   if (q.status == "") {
    //     q.status = null
    //   }
    //   if (q.type == "") {
    //     q.type = null
    //   }
    //   let url = "/backstage/export/export?exportType=orderExport&userId=" + this.userId
    //   if (startTime != null && startTime != "") {
    //     url += "&startTime=" + startTime
    //   }
    //   if (endTime != null && endTime != "") {
    //     url += "&endTime=" + endTime
    //   }
    //   if (q.status != null && q.status != "") {
    //     url += "&status=" + q.status
    //   }
    //   if (q.type != null && q.type != "") {
    //     url += "&type=" + q.type
    //   }
    //   const target = document.createElement("a")
    //   target.href = url
    //   target.target = "_blank"
    //   target.click()
    // },
  },
}
</script>
