<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.name" type="text" placeholder="名称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.nickname" type="text" placeholder="昵称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.shopName" type="text" placeholder="店铺名称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.storeName" type="text" placeholder="到店店铺" />
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :data="items" :columns="tableColumns" style="width: 100%" :loading="tableLoading" />
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showUserOrder" title="用户订单列表" width="80%">
      <UserOrderPage :userId="orderUserId" ref="editor" />
    </Modal>
    <Modal v-model="showUserItem" title="用户商品列表" width="80%">
      <UserItemPage :userAlias="itemUserAlias" ref="editor" />
    </Modal>
    <Modal v-model="showUserSession" title="用户会话列表" width="80%">
      <UserSessionPage :userId="sessionUserId" ref="editor" />
    </Modal>
    <Modal v-model="showUserInviteAndCredit" title="用户邀请和积分列表" width="80%">
      <UserInviteAndCreditPage :userId="inviteAndCreditUserId" ref="editor" />
    </Modal>
    <Drawer :closable="false" width="640" v-model="billShow">
      <p :style="pStyle">用户钱包信息</p>
      <div class="demo-drawer-profile">
        <Row>
          <Col span="12"> 总收入: <span v-text="bill.incomeAmount"></span></Col>
          <Col span="12"> 总支出: <span v-text="bill.outcomeAmount"></span></Col>
        </Row>
        <Row>
          <Col span="12"> 冻结额度: <span v-text="bill.frozenAmount"></span></Col>
          <Col span="12"> 总手续费: <span v-text="bill.serviceFeeAmount"></span></Col>
        </Row>
        <Row>
          <Col span="12"> 可提现额度: <span v-text="bill.availableAmount"></span></Col>
          <Col span="12"> 更新时间: <span v-text="bill.updatedAt"></span></Col>
        </Row>
      </div>
      <Divider />
    </Drawer>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryUser, queryUserBill, openUserShop } from "@/api"
import UserOrderPage from "@/components/user/UserOrderPage.vue"
import UserSessionPage from "@/components/user/UserSessionPage.vue"
import UserItemPage from "@/components/user/UserItemPage.vue"
import UserInviteAndCreditPage from "@/components/user/UserInviteAndCreditPage.vue"
import { centiToOne, toHumanDate } from "@/util"

import "@/components/tableUtil"

export default {
  name: "UserQueryPage",
  components: { ContentWrapper, Pager, UserOrderPage, UserSessionPage, UserItemPage, UserInviteAndCreditPage },
  data() {
    return {
      topLinks: [{ title: "用户列表", link: this.$route.fullPath }],
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      tableLoading: false,
      showUserOrder: false,
      showUserSession: false,
      showUserItem: false,
      showUserInviteAndCredit: false,
      orderUserId: 0,
      sessionUserId: 0,
      inviteAndCreditUserId: 0,
      itemUserAlias: "",
      billShow: false,
      pStyle: {
        fontSize: "16px",
        color: "rgba(0,0,0,0.85)",
        lineHeight: "24px",
        display: "block",
        marginBottom: "16px",
      },
      bill: {
        incomeAmount: 0,
        outcomeAmount: 0,
        frozenAmount: 0,
        serviceFeeAmount: 0,
        availableAmount: 0,
        updatedAt: 0,
      },
      tableColumns: [
        { title: "用户 ID", key: "id", fixed: "left", width: 80, maxWidth: 80 },
        { title: "用户唯一标识", key: "alias", fixed: "left", width: 300, maxWidth: 300 },
        { title: "通行证 ID", key: "passportUid", fixed: "left", width: 90, maxWidth: 90 },
        { title: "名称", key: "name", width: 100, maxWidth: 100 },
        { title: "昵称", key: "nickname", width: 150, maxWidth: 150 },
        { title: "店铺名", key: "shopName", width: 150, maxWidth: 300 },
        { title: "到店店铺", key: "storeName", width: 150, maxWidth: 300 },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "查看",
          render: (h, p) =>
            h("ButtonGroup", [
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.orderUserId = p.row.id
                      this.showUserOrder = true
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-cart" } }), "订单"],
              ),
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.itemUserAlias = p.row.alias
                      this.showUserItem = true
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-pricetag" } }), "发布商品"],
              ),
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.showBill(p.row.id)
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-cash" } }), "钱包"],
              ),
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.sessionUserId = p.row.id
                      this.showUserSession = true
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-contacts" } }), "登录会话"],
              ),
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.inviteAndCreditUserId = p.row.id
                      this.showUserInviteAndCredit = true
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-snow" } }), "邀请和积分"],
              ),
              h(
                "Button",
                {
                  on: {
                    click: async () => {
                      const url = await openUserShop(p.row.id)
                      if (typeof url == "string") {
                        this.$Modal.confirm({
                          closable: true,
                          okText: "我已知晓, 确定打开",
                          title: "危险操作",
                          content:
                            "你将可以直接操作目标店铺的数据, 这可能会对商家造成不可挽回的损失, 你的行为将会被记录. 除非确实需要, 否则请不要轻易使用",
                          onOk: () => {
                            window.open(url, "_blank")
                          },
                        })
                      }
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-card" } }), "商家后台"],
              ),
              h(
                "Button",
                {
                  on: {
                    click: async () => {
                      window.open(p.row.h5url)
                    },
                  },
                },
                [h("Icon", { props: { type: "ios-card" } }), "打开h5店铺页面"],
              )
            ]),
          width: 650,
          maxWidth: 650,
        },
      ],
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        const name = q.name === "" ? null : q.name
        const nickname = q.name === "" ? null : q.nickname
        const shopName = q.shopName === "" ? null : q.shopName
        const storeName = q.storeName === "" ? null : q.storeName
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryUser({
          page: this.page,
          name,
          nickname,
          shopName,
          storeName,
          startTime,
          endTime
        })
        this.items = items
        this.itemListCount = count
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
    async showBill(userId) {
      const bill = await queryUserBill(userId)
      this.bill.incomeAmount = centiToOne(bill.incomeAmount)
      this.bill.outcomeAmount = centiToOne(bill.outcomeAmount)
      this.bill.frozenAmount = centiToOne(bill.frozenAmount)
      this.bill.serviceFeeAmount = centiToOne(bill.serviceFeeAmount)
      this.bill.availableAmount = centiToOne(bill.availableAmount)
      this.bill.updatedAt = toHumanDate(bill.updatedAt, "-")
      this.billShow = true
    }
  },
}
</script>
<style>
.demo-drawer-profile {
  font-size: 14px;
}

.demo-drawer-profile .ivu-col {
  margin-bottom: 12px;
}
</style>
