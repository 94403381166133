<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.orderNo" type="text" placeholder="订单号" />
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.type" placeholder="用户属性">
          <Option value="">不限</Option>
          <Option value="BUYER">买家</Option>
          <Option value="SELLER">卖家</Option>
        </Select>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.status" placeholder="状态">
          <Option value="">不限</Option>
          <Option value="FINISHED">已完成</Option>
          <Option value="PENDING">待支付</Option>
          <Option value="PAID">已支付待发货</Option>
          <Option value="DELIVERED">已发货</Option>
          <Option value="CLOSED">已关闭</Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="订单创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.orderBy" placeholder="排序">
          <Option value="CREATED_AT_DESC">创建时间倒序</Option>
          <Option value="CREATED_AT_ASC">创建时间顺序</Option>
          <Option value="PAID_AT_DESC">支付时间倒序</Option>
          <Option value="PAID_AT_ASC">支付时间顺序</Option>
          <Option value="CONFIRMED_AT_DESC">收货时间倒序</Option>
          <Option value="CONFIRMED_AT_ASC">收货时间顺序</Option>
        </Select>
      </FormItem>
      <FormItem>
        <Button type="info" @click="exportData('orderExport')"
        >
          <Icon type="ios-download-outline"></Icon>
          导出用户订单数据
        </Button
        >
      </FormItem>
      <FormItem>
        <Button type="info" @click="exportData('orderGoodsExport')"
        >
          <Icon type="ios-download-outline"></Icon>
          导出订单商品数据
        </Button
        >
      </FormItem>
    </Form>
    <Table stripe :data="itemList" :columns="tableColumns" :loading="tableLoading" ref="table" />
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryUserOrder, closeOrder } from "@/api"
import { centiToOne, badgeOrderStatus, humanOrderStatus, toHumanDate } from "@/util"
import "@/components/tableUtil"

export default {
  name: "UserOrderPage",
  components: { ContentWrapper, Pager },
  props: {
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      topLinks: [{ title: "用户订单列表", link: this.$route.path }],
      itemList: [],
      itemListCount: 0,
      tableLoading: false,
      createdAtRange: ["", ""],
      page: 1,
      queryForm: {
        queryNo: null,
        status: null,
        type: null,
        orderBy: "CREATED_AT_DESC",
      },
      tableColumns: [
        {
          title: "订单号",
          width: 160,
          maxWidth: 160,
          fixed: "left",
          key: "orderNo",
          render(h, p) {
            return h(
              "router-link",
              { props: { to: { name: "OrderDetailPage", query: { orderNo: p.row.orderNo } } } },
              p.row.orderNo,
            )
          },
        },
        {
          title: "卖家",
          key: "sellerNickname",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "买家",
          key: "buyerNickname",
          render(h, p) {
            return h("div", p.row.buyer.nickname)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "金额",
          key: "price",
          render(h, p) {
            return h("div", centiToOne(p.row.price))
          },
          width: 70,
          maxWidth: 70,
        },
        {
          title: "运费",
          key: "shippingFee",
          render(h, p) {
            return h("div", centiToOne(p.row.shippingFee))
          },
          width: 70,
          maxWidth: 70,
        },
        {
          title: "商品价格",
          key: "itemTotalPrice",
          render(h, p) {
            return h("div", centiToOne(p.row.itemTotalPrice))
          },
          width: 70,
          maxWidth: 70,
        },

        {
          title: "状态",
          key: "status",
          render(h, p) {
            return h("div", [badgeOrderStatus(h, p.row.status), humanOrderStatus(p.row.status)])
          },
          width: 70,
          maxWidth: 70,
        },
        {
          title: "自取",
          key: "shippingMethod",
          render(h, p) {
            return h("div", p.row.shippingMethod == "SELF_PICKUP" ? "是" : "否")
          },
          width: 40,
          maxWidth: 40,
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "支付时间",
          key: "paidAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.paidAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "确认收货时间",
          key: "confirmedAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.confirmedAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "操作",
          render: (h, p) =>
            h("ButtonGroup", [
              h(
                "Button",
                {
                  props: { type: "primary" },
                  on: {
                    click: () => {
                      this.$router.push({ name: "OrderDetailPage", query: { orderNo: p.row.orderNo } })
                    },
                  },
                },
                ["详细"],
              ),
              h(
                "Button",
                {
                  props: { type: "warning", disabled: p.row.status === "FINISHED" || p.row.status === "CLOSED" },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "关闭订单",
                        content: "确认是否要关闭订单？ 关闭后客户将无法再次打开此订单",
                        onOk: async () => {
                          try {
                            await closeOrder(p.row.orderNo)
                          } catch (e) {
                            this.$Message.error(e.message)
                          } finally {
                            await this.reload()
                          }
                        },
                      })
                    },
                  },
                },
                ["关闭"],
              ),
            ]),
        },
      ],
    }
  },
  computed: {},
  watch: {
    async userId() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        if (this.userId) {
          this.tableLoading = true
          const q = this.queryForm
          let startTime = null,
            endTime = null
          if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
            startTime = this.createdAtRange[0].getTime()
            endTime = this.createdAtRange[1].getTime()
          }
          if (q.status == "") {
            q.status = null
          }
          if (q.type == "") {
            q.type = null
          }
          if (q.orderBy == "") {
            q.orderBy = null
          }

          const { items, count } = await queryUserOrder(
            this.page,
            q.orderNo,
            q.type,
            this.userId,
            startTime,
            endTime,
            q.orderBy,
            q.status,
          )
          this.itemList = items
          this.itemListCount = count
          this.tableLoading = false
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    exportData(exportType) {
      const q = this.queryForm
      let startTime = ""
      let endTime = ""
      if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
        startTime = this.createdAtRange[0].getTime()
        endTime = this.createdAtRange[1].getTime()
      }
      if (q.status == "") {
        q.status = null
      }
      if (q.type == "") {
        q.type = null
      }
      let url = "/backstage/export/export?exportType=" + exportType + "&userId=" + this.userId
      if (startTime != null && startTime != "") {
        url += "&startTime=" + startTime
      }
      if (endTime != null && endTime != "") {
        url += "&endTime=" + endTime
      }
      if (q.status != null && q.status != "") {
        url += "&status=" + q.status
      }
      if (q.type != null && q.type != "") {
        url += "&type=" + q.type
      }
      const target = document.createElement("a")
      target.href = url
      target.target = "_blank"
      target.click()
    },
  },
}
</script>
