<template>
  <ContentWrapper :links="topLinks">
    <Table stripe :data="itemList" :columns="tableColumns" :loading="tableLoading" ref="table" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { queryUserSession, deleteSession } from "@/api"
import "@/components/tableUtil"

export default {
  name: "UserOrderPage",
  components: { ContentWrapper },
  props: {
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      topLinks: [{ title: "用户会话列表", link: this.$route.path }],
      itemList: [],
      tableLoading: false,
      tableColumns: [
        {
          title: "sessionId",
          key: "id",
          width: 100,
          maxWidth: 100,
        },
        {
          title: "用户昵称",
          key: "userName",
          width: 100,
          maxWidth: 100,
        },
        {
          title: "token",
          key: "token",
          width: 100,
          maxWidth: 100,
        },
        {
          title: "通行证id",
          key: "passportUserId",
          width: 70,
          maxWidth: 70,
        },
        {
          title: "ip",
          key: "ip",
          width: 70,
          maxWidth: 70,
        },

        {
          title: "制造商",
          key: "deviceVendor",
          width: 70,
          maxWidth: 70,
        },
        {
          title: "型号",
          key: "deviceType",
          width: 40,
          maxWidth: 40,
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "过期时间",
          key: "expiredAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.expiredAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "操作",
          render: (h, p) =>
            h("ButtonGroup", [
              h(
                "Button",
                {
                  props: { type: "primary" },
                  on: {
                    click: () => {
                      this.deleteSession(p.row.token)
                    },
                  },
                },
                ["删除"]
              ),
            ]),
        }
      ],
    }
  },
  computed: {},
  watch: {
    async userId() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        if (this.userId) {
          this.tableLoading = true
          const items = await queryUserSession(this.userId)
          this.itemList = items
          this.tableLoading = false
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async deleteSession(sessionId) {
      await deleteSession(sessionId)
      await this.reload()
    },
  },
}
</script>
