<template>
  <Form :labelWidth="100" :disabled="true">
    <FormItem label="">
      <Alert type="warning">
        因为涉及到获取地理位置定位问题，暂时关闭后台编辑权限
      </Alert>
    </FormItem>
    <!--    <FormItem label="图片">-->
    <!--      <div class="uploadedImages">-->
    <!--        <span v-for="m in item.medias" :key="m.path">-->
    <!--          <img v-real-img="m.url" style="width:80px; height: 80px" />-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </FormItem>-->
    <FormItem label="商品图片">
      <div v-if="uploadedImages.length === 0">暂未上传图片</div>
      <div class="uploadedImages" v-else>
        <span class="imgWrapper" v-for="i in uploadedImages" :key="i.path">
          <img v-real-img="i.url" style="width: 80px; height: 80px" />
          <Button class="imgClose" shape="circle" icon="md-close" @click="removeUploadImage(i.path)"></Button>
        </span>
      </div>
    </FormItem>
    <FormItem label="">
      <Upload
        multiple
        action="/backstage/media/uploadImages"
        :headers="{ 'X-Auth': token }"
        name="images"
        :format="['jpg', 'jpeg', 'png']"
        :max-size="1024"
        :before-upload="filesReadyToUpload"
        :on-success="filesUploaded"
        :show-upload-list="false"
      >
        <Button icon="ios-cloud-upload-outline">上传</Button>
      </Upload>
    </FormItem>
    <FormItem label="唯一标识">
      {{ item.itemAlias }}
    </FormItem>
    <FormItem label="标题">
      <Input v-model="item.title" placeholder="请输入标题" />
    </FormItem>
    <FormItem label="商品分类">
      <Select v-model="item.categoryAlias">
        <Option v-for="item in categoryInList" :value="item.alias" :key="item.alias">
          {{ item.name }}
        </Option>
      </Select>
    </FormItem>
    <FormItem label="内容">
      <Input v-model="item.detailContent" type="textarea" :rows="4" placeholder="Enter something..." />
    </FormItem>
    <FormItem label="价格">
      <Input v-model="item.price" placeholder="请输入价格" />
    </FormItem>
    <FormItem label="数量">
      <Input v-model="item.stockQuantity" placeholder="请输入数量" />
    </FormItem>
    <FormItem label="支持配送">
      <CheckboxGroup v-model="item.shipment.shippingMethods">
        <Checkbox label="SELF_PICKUP">自提</Checkbox>
        <Checkbox label="DELIVERY_BY_EXPRESS">物流</Checkbox>
      </CheckboxGroup>
    </FormItem>
    <FormItem v-if="item.shipment.shippingMethods.indexOf('DELIVERY_BY_EXPRESS') > -1">
      <RadioGroup v-model="item.shipment.shippingFeeType">
        <Radio label="ONE">免运费</Radio>
        <Radio label="CALC">计算运费</Radio>
        <Radio label="FIXED">固定运费</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem
      v-if="
        item.shipment.shippingMethods.indexOf('DELIVERY_BY_EXPRESS') > -1 && item.shipment.shippingFeeType === 'FIXED'
      "
    >
      <Table :data="item.shipment.shippingFees" :columns="shipmentFeesTable" size="small" />
    </FormItem>
    <FormItem
      label="物流尺寸"
      v-if="
        item.shipment.shippingMethods.indexOf('DELIVERY_BY_EXPRESS') > -1 && item.shipment.shippingFeeType === 'CALC'
      "
    >
      <p>宽 {{ item.sizeAndWeight.width / 10 }} cm</p>
      <p>高 {{ item.sizeAndWeight.height / 10 }} cm</p>
      <p>长 {{ item.sizeAndWeight.length / 10 }} cm</p>
      <p>重 {{ item.sizeAndWeight.weight }} g</p>
    </FormItem>
    <!--      <FormItem label="有效期限">-->
    <!--          <Select v-model="trem">-->
    <!--              <Option-->
    <!--                      v-for="t in availableTerms"-->
    <!--                      :key="t.term"-->
    <!--                      :label="t.name"-->
    <!--                      :value="t.term"-->
    <!--              >-->
    <!--                  {{ t.name }}-->
    <!--              </Option>-->
    <!--          </Select>-->
    <!--      </FormItem>-->
    <FormItem label="上架时间">
      {{ toHumanDate(item.listedAt, "-", false) }}
    </FormItem>
    <FormItem label="下架时间">
      {{ toHumanDate(item.discontinuedAt, "-", false) }}
    </FormItem>
  </Form>
</template>

<script>
import { getAvailableTerms, getItemDetail, queryCategories } from "@/api"
import { centiToOne, toHumanDate } from "@/util"

const initData = () => {
  return {
    shipment: {
      shippingMethods: [],
    },
  }
}
const maxFiles = 9
export default {
  name: "ItemDetail",
  components: {},
  props: {
    alias: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      item: initData(),
      centiToOne,
      toHumanDate,
      availableTerms: [],
      uploadedImages: [],
      categories: [],
      categoryInList: [],
      shipmentFeesTable: [
        { title: "展示名称", key: "targetLocation" },
        { title: "物流价格", key: "shippingFee" },
      ],
      token: sessionStorage.getItem("token"),
    }
  },
  computed: {},
  watch: {
    async alias() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.categoryInList.push({ name: cate.name, alias: cate.alias })
      return cate
    },
    async reload() {
      try {
        if (this.alias) {
          this.item = await getItemDetail(this.alias)
          this.item.price = centiToOne(this.item.price)
          this.uploadedImages = this.item.medias
          this.categoryInList = []
          const root = this.process(await queryCategories())
          this.categories = [root]
          this.availableTerms = await getAvailableTerms()
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    filesReadyToUpload() {
      if (1 + this.uploadedImages.length > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片`)
        return false
      }
    },
    filesUploaded(response) {
      if (this.uploadedImages.length + 1 > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片, 多余的图片将被忽略`)
        return
      }
      this.uploadedImages = this.uploadedImages.concat(response.data)
    },
    removeUploadImage(key) {
      this.uploadedImages = this.uploadedImages.filter(image => image.path != key)
    },
  },
}
</script>

<style></style>
